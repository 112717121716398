<template>
  <v-card>
    <v-card-title class="">
      <span class="me-3">({{ njangi_groups.length }}) Njangi Groups</span>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="njangi_groups"
      item-key="id"
      class="table-rounded"
      :items-per-page="10"
      disable-sort
      :search="search"
    >
      <!-- name -->
      <template #[`item.name`]="{item}">
        <span style="cursor: pointer"
          @click.stop="dialog=true"
          @click="getNjangiGroupSetting(item.id)" class="d-block font-weight-semibold text--primary text-truncate">
          {{ item.name }}
        </span>
      </template>
      <template #[`item.admin`]="{item}">
        {{ item.admin }}
      </template>
      <!-- status -->
      <template #[`item.njangi_contact`]="{item}">
        {{ item.njangi_contact }}
      </template>

      <template #[`item.get_date_str`]="{item}">
        {{ item.get_date_str }}
      </template>
    </v-data-table>
    <v-dialog width="500px" v-model="dialog"> 
        <v-card v-if="loading">
          <div class="text-center pa-4">
            <font-awesome-icon icon="fa-solid fa-spinner" style="font-size: 36px!important; color: blue"/>
            <p>Loading...</p>
          </div>
        </v-card>
        <v-card v-else-if="!delete_njangi && !loading"> 
          <v-card-title class="">Njangi Details</v-card-title> 
          <v-card-text class="black--text pt-2">
            <div class="p-4">
              <div class="text-right">
                  <v-btn small class="" rounded color="error" @click="delete_njangi = true" >
                    <span> Delete Now</span>
                  </v-btn>
              </div>
              <h3 class="text-left" ><b>Njangi Name: </b> {{ njangi_group_name }} </h3>
              <hr>
              <v-card-text class="body-1 pa-4 mb-0" style="font-size: 12px; padding: 5px">
                  <v-layout row wrap class="">
                      <v-flex xs12 class="py-0 my-0">
                          <span class="caption"> <b>Njangi Description:</b> <p class="dark--text caption pa-0 ma-0 " blue v-html="njangi_group_description"></p></span>
                      </v-flex>
                      <v-flex xs12 md6 class="pt-0 mt-0">
                          <span class="caption"> <b>Njangi Admin Contact:</b> {{ njangi_contact }} </span>
                      </v-flex>
                      <v-flex xs12 md6 class="pt-0 mt-0">
                          <span class="caption"> <b>Contributions:</b>  {{ contribution_amount }} F CFA per person {{ collecting_cycle }} </span>
                      </v-flex>
                      <v-flex v-if="!collection_activated" xs12 md6 class="pt-0 mt-0">
                          <span class="caption"> <b>Start Date:</b> {{ start_date }} </span>
                      </v-flex>
                  </v-layout>
                  <v-spacer></v-spacer>
              </v-card-text>
              <hr>
              <div class="pa-2">
                  <h3 v-if="collecting_cycle === 'Daily'" ><u>Today</u></h3>
                  <h3 v-if="collecting_cycle === 'Weekly'" ><u>This Week</u></h3>
                  <h3 v-if="collecting_cycle === 'Monthly'" ><u>This Month</u></h3>
                  <p class="pa-0 ma-1"><b>Collecting:</b> {{ current_collector }}</p>
                  <p class="pa-0 ma-1"><b>No Contributed:</b> {{ no_contributed }}/{{ no_members }}</p>
                  <p class="pa-0 ma-1"><b>Amount Contributed:</b> {{ contributed_amount }} F CFA/ {{ expected_amount }} F CFA</p>
                  <p v-if="collection_activated" class="pa-0 ma-1"><b>Collection Date:</b> {{ collection_date }}</p>
              </div>
              <hr>

              <h2 v-if="njangiRequestedTransactions.length !== 0" class="mt-3 dark--text font-weight-bold text-xs-left"><u>Requested Transactions</u></h2>
              <div v-if="njangiRequestedTransactions.length !== 0" class="white elevation-6 pa-2" style="max-height: 50px; overflow: auto; border-radius: 7px"> 
                  <div color="white" style="" v-for="item in njangiRequestedTransactions" :key="item.id">
                      <v-card-text class="pa-1" style="font-size: 12px;">
                          <v-row wrap v-if="!item.cancelled || item.withdrawal || (!item.withdrawal && !item.confirmed)">
                              <v-col cols="8" class="text-left">
                                  <div v-if="item.withdrawal" > <!-- this will be an interrupt-->
                                      <span class="red--text font-weight-bold">Interrupt:</span><span> {{item.amount}} FCFA</span>
                                  </div>
                                  <div v-else>
                                      <span class="dark--text font-weight-bold">Deposit:</span><span> {{item.amount}} FCFA</span>
                                  </div>
                                  <div v-if="!item.confirmed && !item.cancelled" xs8 text-xs-left>
                                      <span class="font-weight-bold ">Status:</span><span class="primary--text font-weight-bold "> Pending </span>
                                  </div>
                                  <div v-if="item.confirmed" xs8 text-xs-left>
                                      <span class="font-weight-bold ">Status:</span><span class="success--text font-weight-bold "> Confirmed </span>
                                  </div>
                              </v-col>
                              <v-col cols="4" class="text-right">
                                  <span class="dark--text font-weight-bold">Date:</span><span> {{item.get_date_str}}</span>
                              </v-col>
                          </v-row>
                      </v-card-text>
                  <v-divider></v-divider>
                  </div>
              </div>
              <br>

              <div class="white elevation-6" style="border-radius: 10px">            
                  <div class="text-white primary pa-2" style="border: 1px solid grey">
                    <h3 style="color: white!important" >Njangi Members (Contribution History) </h3>
                  </div>
                  <div class="text-center pt-1">
                    <label v-if="collecting_cycle === 'Daily'" for="date_history"><b>Choose Date: </b></label>
                    <label v-if="collecting_cycle === 'Weekly'" for="date_history"><b>Choose Week: </b></label>
                    <label v-if="collecting_cycle === 'Monthly'" for="date_history"><b>Choose Month: </b></label>
                    
                    <input @change="getContribHistory(njangi_id)" v-if="collecting_cycle === 'Daily'" type="date" v-model="date_history" id="date_history"/>
                    <input @change="getContribHistory(njangi_id)" v-else-if="collecting_cycle === 'Weekly'" type="week" v-model="date_history" id="date_history"/>
                    <input @change="getContribHistory(njangi_id)" v-else-if="collecting_cycle === 'Monthly'" type="month" v-model="date_history" id="date_history"/>
                  </div>
                  <div class="text-xs-left pa-1">
                    <hr>
                    <small class="pa-0 ma-1"><b>Amount Contributed:</b> {{ history_contributed_amount }} F CFA/ {{ history_expected_amount }} F CFA</small>
                    <hr>

                    <ul v-for="item in njangi_members" :key="item.id" >
                      <li class="text-xs-left" v-if="item.contributed">{{ item.member_name }} (<font-awesome-icon icon="fa-solid fa-check" style="color: blue!important;"/>Contributed)</li>
                      <li class="text-xs-left" v-else>{{ item.member_name }} (<font-awesome-icon icon="fa-solid fa-xmark" style="color: red!important;"/> Didn't contribute)</li>
                    </ul>                
                    </div>
                </div>
          </div>

          </v-card-text> 
          <v-divider></v-divider> 
          <v-card-actions> 
            <v-spacer></v-spacer> 
            <v-btn color="success" text @click="dialog = false" > Close </v-btn> 
          </v-card-actions> 
        </v-card> 
        <v-card v-else class="text-center">
          <v-card-title class="">Are You Sure You Want To Delete This Njangi</v-card-title> 
          <h4 class="text-center" style="color: red;">{{ njangi_group_name }}</h4>
          <v-btn color="success" text @click="delete_njangi = false" > Cancel </v-btn> 
          <v-btn small class="" rounded color="error" @click="deleteNjangi(njangi_id)" >
            <span> Delete Now</span>
          </v-btn>
        </v-card>
    </v-dialog> 
  </v-card>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical } from '@mdi/js'
import axios from 'axios'

export default {
  components: {
  },

  data() {
    return {
      switch1: true,
      headers: [
        { text: 'Njangi Name', value: 'name' },
        { text: 'Admin', value: 'admin' },
        { text: 'Contact', value: 'njangi_contact' },
        { text: 'Date/Time', value: 'get_date_str' },
      ],
      njangi_groups: [],
      search: '',
      selectedStartDate: null,
      selectedEndDate: null,
      loading: false,

      dialog: false,
      njangi_id: "",
      njangi_members: [],
      njangi_group_name: '',
      njangi_contact: '',
      njangi_group_description: '',
      contribution_amount: '',
      collecting_interval: '',
      collecting_cycle: '',
      no_contributed: null,
      no_members: null,
      njangiRequestedTransactions: [],
      date_history: "",
      collection_date: "",
      start_date: "",
      collection_activated: "",
      contributed_amount: "",
      expected_amount: "",
      current_collector: "",
      history_contributed_amount: "",
      history_expected_amount: "",

      delete_njangi: false,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },

  mounted(){
      // executes these after the page has been mounted
      document.title = "Manager: Njangi | List of Groups"
      this.getNjangiGroups()
  },

  methods:{
    async getNjangiGroups(){
      if (this.selectedStartDate === ''){
        this.selectedStartDate = 'null'
      }
      if (this.selectedEndDate === ''){
        this.selectedEndDate = 'null'
      }

      this.loading = true
      console.log(this.loading)
      await axios
        .get('/api/v1/manager/njangi/get/groups/'+this.selectedStartDate+'/'+this.selectedEndDate+'/')
        .then(response => {
          this.njangi_groups = response.data  // get the data and fill into campaigns
          console.log(this.njangi_groups)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
      this.loading = false
    },
    async getClientRequestedNjangiTransactions(group_id){
      await axios
        .get('/api/v1/njangi/get/user/group/'+group_id+'/requested_transactions/')
        .then(response => {
            this.njangiRequestedTransactions = response.data  // get the data and fill into campaigns
            console.log(this.njangiRequestedTransactions)
        })
        .catch(error => {  
            if (error.response){
                this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                this.$store.commit('setSnackBarColor', "red darken-4")
                this.$store.commit('activateSnackBar', true)
            }else{
                this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                this.$store.commit('setSnackBarColor', "red darken-4")
                this.$store.commit('activateSnackBar', true)
            }
        })
    },

    async getContribHistory(group_id){
        await axios
            .get('/api/v1/njangi/get/user/group/members/'+group_id+'/'+this.date_history+'/')
            .then(response => {
                console.log(response.data)
                this.njangi_members = response.data.group_members_contrib_info
                this.history_contributed_amount = response.data.total_contributed
                this.history_expected_amount = response.data.expected_contribution
            })
            .catch(error => { 
                if (error.response){
                    this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                }else{
                    this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                }
            })
    },

    async getNjangiGroupSetting(group_id){
        await axios
            .get('/api/v1/njangi/get/user/group/setting/detail/'+group_id+'/')
            .then(response => {
              console.log(response.data)
              this.njangi_id = response.data[1].njangi_group_id
              this.njangi_group_name = response.data[1].njangi_group_name
              this.njangi_group_description = response.data[1].njangi_group_description
              this.njangi_contact = response.data[1].njangi_contact
              this.contribution_amount = response.data[1].contribution_amount
              this.collecting_cycle = response.data[1].collecting_cycle
              this.no_contributed = response.data[1].get_no_contribution_members
              this.no_members = response.data[1].get_no_members
              this.contributed_amount = response.data[1].contributed_amount
              this.expected_amount = response.data[1].expected_amount
              this.current_collector = response.data[1].get_current_collector
              this.date_history = response.data[1].get_date_history
              this.collection_date = response.data[1].get_collection_date
              this.start_date = response.data[1].get_start_date
              this.collection_activated = response.data[1].collection_activated
              this.njangi_members = response.data[2].group_members_contrib_info
              this.history_contributed_amount = response.data[2].total_contributed
              this.history_expected_amount = response.data[2].expected_contribution
              this.getClientRequestedNjangiTransactions(group_id)
            })
            .catch(error => { 
                if (error.response){
                    this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                }else{
                    this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                }
            })
    },

    async deleteNjangi(group_id){
        this.dialog = false
        this.loading = true
        await axios
            .post('/api/v1/manager/njangi/delete/'+group_id+'/')
            .then(response => {
              this.$store.commit('setSnackBarMessage', response.data[0].text)
              this.$store.commit('setSnackBarColor', "primary darken-4")
              this.$store.commit('activateSnackBar', true)
              this.getNjangiGroups()
            })
            .catch(error => { 
              if (error.response){
                this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                this.$store.commit('setSnackBarColor', "red darken-4")
                this.$store.commit('activateSnackBar', true)
              }else{
                this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                this.$store.commit('setSnackBarColor', "red darken-4")
                this.$store.commit('activateSnackBar', true)
              }
            })
        this.loading = false
    },

  },
}
</script>

<style scoped>

  /* Define an animation behavior */
  @keyframes spinner {
    to { transform: rotate(360deg); }
  }
  /* This is the class name given by the Font Awesome component when icon contains 'spinner' */
  .fa-spinner {
    /* Apply 'spinner' keyframes looping once every second (1s)  */
    animation: spinner 1s linear infinite;
    font-family: Roboto, system, -apple-system, "system-ui", ".SFNSDisplay-Regular", "Helvetica Neue", Helvetica, Arial, sans-serif !important;

  }
</style>